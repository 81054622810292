<template lang="">
    <Add/>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Yeni İhale Ekle'},
    ]);
  },
components: {
    
  Add: () => import("@/components/adverts/Add.vue"),
},
};
</script>
